import Home from "./pages/Home";
import HRBP from "./pages/HRBP";
import Managers from "./pages/Managers";
import Professionals from "./pages/Professionals";
import Recruiters from "./pages/Recruiters";

//Styling
import "./styles/App.css";

// Components
import Navbar from "./components/Navbar";

//Routing
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/HRBP" element={<HRBP />}></Route>
          <Route path="/Managers" element={<Managers />}></Route>
          <Route path="/Professionals" element={<Professionals />}></Route>
          <Route path="/Recruiters" element={<Recruiters />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
