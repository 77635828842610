import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    green: {
      100: "#D5E376",
      200: "#B2C868",
    },
    orange: "#FF4800",
    blueGrey: {
      100: "#BDCFD5",
      200: "#8DA2A9",
    },
  },
  fonts: {
    heading: "owners-xwide, Arial, sans-serif",
    body: "owners, Roboto, sans-serif",
  },

  // ============================
  components: {
    Button: {
      baseStyle: {
        borderRadius: "full",
        fontSize: "xs",
        letterSpacing: "wide",
        fontWeight: "normal",
        mb: "2",
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "semibold",
        mb: "4",
      },
    },
    Text: {
      baseStyle: {
        fontWeight: "regular",
        lineHeight: "tall",
        letterSpacing: "wide",
        mb: "4",
      },
    },
  },
});

export default theme;
