import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  MenuItem,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logoHorizontal from "../assets/logo-horizontal.svg";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg="green.200" px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <img src={logoHorizontal} alt="batlogo" />

        <HStack spacing={8} alignItems="center">
          <HStack as="nav" spacing={4} display={{ base: "none", md: "flex" }}>
            <Link to="/Managers">
              <Button variant="link" color="black">
                For hiring managers
              </Button>
            </Link>
            <Link to="/HRBP">
              <Button variant="link" color="black">
                For HRBP's
              </Button>
            </Link>
            <Link to="/Recruiters">
              <Button variant="link" color="black">
                For recruiters
              </Button>
            </Link>
            <Link to="/Professionals">
              <Button variant="link" color="black">
                For registered professionals
              </Button>
            </Link>
          </HStack>
          <Button
            onClick={() =>
              window.open("https://reportengine.pages.dev/", "_blank")
            }
            bg="blueGrey.200"
            color="white"
          >
            Login/Register
          </Button>
        </HStack>

        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as="nav" spacing={4}>
            <MenuItem>Home</MenuItem>
            <MenuItem>About</MenuItem>
            <MenuItem>Services</MenuItem>
            <MenuItem>Contact</MenuItem>
            <Button colorScheme="teal" variant="outline">
              Sign In
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Navbar;
