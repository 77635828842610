import React from "react";
import { Heading, Button, Text, Box, Grid, GridItem } from "@chakra-ui/react";
//Images
import reportImg from "../assets/report.png";
import headerImg from "../assets/headerImage.png";
import compImg from "../assets/campaign-creators-pypeCEaJeZY-unsplash.jpg";
import chooseImg from "../assets/emma-dau-n_4iTY1KmDE-unsplash.jpg";
import logoHorizontal from "../assets/logo-horizontal.svg";

export default function Home() {
  return (
    <div>
      <header className="header">
        <img src={headerImg} className="headerImg" alt="headerimage" />

        <div className="desktopHeader">
          <Heading as="h2">Integrated competency reports, in seconds.</Heading>
          <Text>
            Mix it up - employee performance indicators plus 360 assessment
            measurements plus psychometric assessment scores, all in one report.
            Whether its for recruitment or development, make use of our
            extensive experience in generating thousands of integrated reports
            for international organisations.
          </Text>
          <Button bg="orange" color="white">
            Book an appointment
          </Button>
        </div>
      </header>

      <section className="reportSection">
        <img src={reportImg} className="reportImg" alt="reportImg" />
      </section>
      <section className="secretSection">
        <Heading as="h3">Your recipe is the secret sauce.</Heading>
        <Text>
          You have various bits and pieces of information for your talented
          resources scattered all over the place. Business stakeolders want
          meaningful representation of a person, but for differing decision
          making events, and at ad-hoc times. And then they may want to use that
          information for comparison purposes.Those pieces of information may
          need to simply be represented as-is on a report, but without the time
          and effort of looking it up each time.  Some pieces are best
          represented when used to derive useful measures together with other
          pieces of information.  And of course some information offers its best
          value when displayed next to comparison data.
        </Text>

        <Text fontSize="md">
          Any figure of measurement is better when dressed with relevant
          context-based explanation that is useful to the intended reader.The
          synthesis of measurements plus the text-based explanations, and in
          context of standard measures is unique to your organsation and allows
          you to create a consistent and transparent representation of human
          resources specifically aligned to your organisation.
        </Text>
      </section>

      <section className="hrSection">
        <Grid templateColumns="repeat(4, 1fr)" gap={10}>
          <GridItem colSpan={2}>
            <Box>
              <Heading as="h3" size="lg">
                Make your human resource data work for you, easily.
              </Heading>
              <Text fontSize="md">
                Simply upload spreadsheets or indvidual reports once and get the
                ease of generating multiple reports from one single place.
              </Text>

              <Text fontSize="md">
                Let us design those reports to ensure accurate and consistent
                measures together with appropriate text explanations so that you
                can be sure business stakeholders are getting exactly what they
                need, when they need it.
              </Text>

              <Text fontSize="md">
                We can even build integrations to where your data stores are
                today, so your data is readily on tap for reporting.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <img
              src={compImg}
              alt="computer"
              style={{ height: "100%", objectFit: "cover" }}
            />
          </GridItem>
        </Grid>
      </section>

      <section className="whyusSection">
        <Grid templateColumns="repeat(4, 1fr)" gap={10}>
          <GridItem colSpan={2}>
            <img
              src={chooseImg}
              alt="computers"
              style={{ height: "100%", objectFit: "cover" }}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Box>
              <Heading as="h3" size="lg">
                Why choose us
              </Heading>
              <Text fontSize="md">
                Having built systems that generated thousands of integrated
                reports for international clients, spanning many continents and
                across multiple languages, we understand the need for generating
                quality reports for business stakeholders.
              </Text>
              <Heading as="h5" size="sm">
                Talent management data becomes an enabler
              </Heading>

              <Text fontSize="md">
                Following an employee from their recruitment to their most
                senior promotion, data is a natural consequence of attracting
                and retaining your talent. But unless that data is available in
                a timely, consistent and human readable format, talent
                management becomes a recurring cycle of hit and miss.
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </section>

      <section className="colSection">
        <Box className="colBox">
          <Heading as="h5" size="sm">
            What you do
          </Heading>

          <Text fontSize="md">
            As part of the recruitment and development of human talent you make
            use of psychometric, 360 and performance assessments. From
            recruitment to onboarding, promotion to succession planning -
            business stakeholders require science-based reports to assist their
            decision making and planning efforts.As the journey of an individual
            progresses through an organisation, their history grows, their
            context changes and so their potential evolves.Leveraging off known
            psychomtric assessment scores, and adding an accumulating amount of
            job-specific measurements, you are required to apply a consistent
            framework to reports that stakeholders can understand and apply
            appropriately.
          </Text>
        </Box>
        <Box className="colBox">
          <Heading as="h5" size="sm">
            Your challenges
          </Heading>

          <Text fontSize="md">
            Various assessments are sourced from various parties inside and
            outside the organisation. Storing those reports, extracting the
            various scores, collating the relevant scores and presenting
            consistent human-readable reports necessitates constant monitoring
            and oversight by registered professionals.Relegating qualified
            professionals to rudimentary admin tasks means more time is spent on
            generating reports than interpreting and advising on the contents.
            Add to that the benefit of trending an individuals journey against
            relevant peers, deepens the emphasis on rinse-and-repeat activities
            before business stakeholders can get the most valueble reports into
            their decision process.
          </Text>
        </Box>
        <Box className="colBox">
          <Heading as="h5" size="sm">
            Your sources
          </Heading>

          <Text fontSize="md">
            Custom performance measurements, 9 box grid, 360 assessments, EQi,
            MBTI, HPI, HDS, MVPI, MCPA, CPP, Big five, DISC, Ravens, 16pf
            etcThese typically are referenced from various spreadsheets stored
            in different places within an organisaton. The spreadsheets in turn
            contain links to documents stored within an organisation as well as
            on thrid party service providers and often only carry implied access
            control if you know the document link and/or you have access to the
            third party site.
          </Text>
        </Box>
      </section>
      <section className="contactSection">
        <Box className="scheduleBox">
          <Heading as="h3" size="lg" color={"white"}>
            Schedule a virtual appointment today.
          </Heading>
          <Text fontSize="md" color={"white"}>
            We would love to hear about your talent management journey. Book a
            chat with us and lets see if our extensive experience and possibly a
            demonstration can help you in any way.
          </Text>
          <Button bg="orange" color="white">
            Book an appointment
          </Button>
        </Box>
      </section>

      <footer>
        <img src={logoHorizontal} alt="batlogo" />
      </footer>
    </div>
  );
}
